// extracted by mini-css-extract-plugin
export var accordionTitle = "ProductPage-module--accordionTitle--8c93d";
export var actionContainer = "ProductPage-module--actionContainer--89b94";
export var addToButtonContainer = "ProductPage-module--addToButtonContainer--bb510";
export var attributeContainer = "ProductPage-module--attributeContainer--f1873";
export var content = "ProductPage-module--content--9570b";
export var description = "ProductPage-module--description--f6ecb";
export var details = "ProductPage-module--details--046f4";
export var gallery = "ProductPage-module--gallery--9c984";
export var heartFillContainer = "ProductPage-module--heartFillContainer--a532a";
export var hide = "ProductPage-module--hide--bdd33";
export var information = "ProductPage-module--information--e48ab";
export var informationContainer = "ProductPage-module--informationContainer--c4284";
export var priceContainer = "ProductPage-module--priceContainer--52cd1";
export var quantityContainer = "ProductPage-module--quantityContainer--7a608";
export var root = "ProductPage-module--root--bb6bb";
export var show = "ProductPage-module--show--2afb2";
export var sizeContainer = "ProductPage-module--sizeContainer--a8504";
export var suggestionContainer = "ProductPage-module--suggestionContainer--8bb7b";
export var vendor = "ProductPage-module--vendor--a04da";
export var wishlistActionContainer = "ProductPage-module--wishlistActionContainer--fde3e";